import CommonIcon from '@/components/common-icon';
import { BasicModal } from '@/components/modal';
import { DesktopOrTablet, Mobile } from '@/components/responsive';
import { LANG } from '@/core/i18n';
import { MediaInfo } from '@/core/utils';
import type { TabsProps } from 'antd';
import { Tabs } from 'antd';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import { GlobalConfigDrawer } from '../drawer/';
const LangContent = dynamic(() => import('../lang-content'));
const FiatList = dynamic(() => import('../fiat-list'));

const GlobalIcon = ({ className }: { className?: string }) => {
  const [hover, setIsHover] = useState(false);
  const [showModal, setShowModal] = useState(false);
  let url = hover ? 'header-global-active-0' : 'header-global';

  const showLanguageModal = () => {
    setShowModal(true);
  };
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: LANG('Languages and regions'),
      children: <LangContent onLanguageItemClick={() => setShowModal(false)} />,
    },
    {
      key: '2',
      label: LANG('Exchange rate'),
      children: <FiatList onClick={() => setShowModal(false)} />,
    },
  ];
  return (
    <>
      <CommonIcon
        name={url}
        size={24}
        className={className}
        onClick={showLanguageModal}
        enableSkin={hover}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      />
      <DesktopOrTablet forceInitRender={false}>
        {showModal && (
          <BasicModal
            open={showModal}
            className='global-language-modal'
            centered
            closable
            onCancel={() => setShowModal(false)}
            okButtonProps={{ style: { display: 'none' } }}
            destroyOnClose
            cancelButtonProps={{ style: { display: 'none' } }}
            width={800}
          >
            <Tabs items={items} />
          </BasicModal>
        )}
      </DesktopOrTablet>
      <Mobile forceInitRender={false}>
        <GlobalConfigDrawer open={showModal} onClose={() => setShowModal(false)}>
          <Tabs items={items} />
        </GlobalConfigDrawer>
      </Mobile>

      <style jsx>{`
        :global(.global-language-modal) {
          :global(.ant-tabs .ant-tabs-tab) {
            padding-bottom: 22px;
          }
          :global(.ant-modal-content) {
            max-height: 450px;

            @media ${MediaInfo.tablet} {
              max-width: 800px;
            }
            background-color: var(--spec-background-color-2);
            :global(.fiat-list) {
              height: 250px;
              overflow-y: scroll;
            }
            :global(.basic-content) {
              padding: 0px 36px !important;
              :global(.ant-tabs-nav) {
                overflow: hidden;
                margin-bottom: 18px;
              }
            }
            :global(.ant-tabs-nav::before) {
              border-bottom: 1px solid rgba(5, 5, 5, 0.06);
            }
          }
          :global(.ant-tabs-nav-list) {
            overflow: hidden;
            :global(.ant-tabs-tab-active) :global(.ant-tabs-tab-btn) {
              color: var(--theme-font-color-1);
            }
            :global(.ant-tabs-tab-btn) {
              color: var(--theme-font-color-2);
              font-size: 18px;
              font-weight: 500;
            }
          }
          :global(.ant-tabs-nav-wrap) {
            :global(.ant-tabs-nav-list) {
              :global(.ant-tabs-ink-bar) {
                background-color: var(--skin-main-font-color);
              }
            }
          }
        }
      `}</style>
    </>
  );
};
export default GlobalIcon;
