export const getOtherLink = () => {
  try {
    const origin = window.location.origin;
    const links = ['666', '678', '777', '888', '999'];

    for (let i = 0; i < links.length; i++) {
      if (origin.indexOf(links[i]) > -1) {
        return `https://download.bydfi999.com`;
      }
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const isSearchRU = () => {
  const search = window.location.search;
  return search.indexOf('ru') > -1;
};

export const getInviteLink = () => {
  const search = window.location.search.replaceAll('?', '').replaceAll('=', ':').replaceAll('&', ',');
  const link = process.env.NEXT_PUBLIC_INVITE_LINK_APP?.replace('{search}', search);
  return getOtherLink() ?? link;
};
